<template>
  <div class="orderMain">
    <h6>订单记录</h6>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
      <template #productName>
        <el-table-column label="订阅状态" align="center">
          <template #default="scoped">
            <span
              :style="{
                color:
                  scoped.row.diamond == 'blue'
                    ? '#389bfa'
                    : scoped.row.diamond == 'orange'
                    ? '#f98d29'
                    : '#9b6cff'
              }"
            >
              {{ scoped.row.productName }}
            </span>
          </template>
        </el-table-column>
      </template>
      <template #status>
        <el-table-column label="订单状态" width="230" align="center">
          <template #default="scoped">
            <div class="btnlinkbox"></div>
            <el-button
              type="success"
              size="small"
              class="btnlink"
              v-if="scoped.row.orderStatus == 3"
            >
              已完成
            </el-button>
            <el-button
              type="danger"
              size="small"
              class="btnlink"
              v-if="scoped.row.orderStatus == 0"
            >
              待支付
            </el-button>
            <el-button
              type="info"
              size="small"
              class="btnlink"
              v-if="scoped.row.orderStatus == 4"
            >
              已关闭
            </el-button>
            <el-button
              type="info"
              size="small"
              class="btnlink"
              v-if="scoped.row.orderStatus == 1"
            >
              支付中
            </el-button>
            <el-button
              type="success"
              size="small"
              class="btnlink"
              v-if="scoped.row.orderStatus == 2"
            >
              已支付
            </el-button>
          </template>
        </el-table-column>
      </template>
      <template #action>
        <el-table-column label="操作" width="230" align="center">
          <template #default="scoped">
            <el-button
              type="warning"
              size="small"
              @click="handleDetails(scoped.row)"
            >
              查看详情
            </el-button>
            <el-button
              type="danger"
              size="small"
              @click="handleCloseOrder(scoped.row)"
              v-if="scoped.row.orderStatus == 0"
            >
              取消订单
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
    <el-dialog
      class="orderDialog"
      v-model="orderDialogVisible"
      title="订单详情"
      width="840"
      :before-close="closeOrderDialogVisible"
    >
      <div class="orderform">
        <div class="item">
          <span class="label">#订单号：</span>
          <span class="content">{{ dialogOrder.orderId }}</span>
        </div>
        <div class="item">
          <span class="label">订单时间：</span>
          <span class="content">{{ dialogOrder.createdAt }}</span>
        </div>
        <div class="item">
          <span class="label">订单状态：</span>
          <span class="content">
            {{ orderStatus(dialogOrder.orderStatus) }}
          </span>
        </div>
        <div class="item">
          <span class="label">订单类型：</span>
          <span
            class="content"
            :class="[
              dialogOrder.diamond == 'orange' ? 'orange' : '',
              dialogOrder.diamond == 'purple' ? 'purple' : '',
              dialogOrder.diamond == 'blue' ? 'blue' : ''
            ]"
          >
            {{ dialogOrder.productName }}
          </span>
        </div>
        <div class="item">
          <span class="label">订单金额：</span>
          <span class="content">{{ dialogOrder.price }}</span>
        </div>
        <div
          class="item"
          v-if="dialogOrder.total && dialogOrder.diamond == 'orange'"
        >
          <span class="label">订单包含tokens数量：</span>
          <span class="content">{{ dialogOrder.total }}</span>
        </div>
        <div class="item" v-if="dialogOrder.orderStatus == 2">
          <span class="label">订阅生效时间：</span>
          <span class="content" v-if="dialogOrder.takingEffectTime != ''">
            {{
              dialogOrder.takingEffectTime + ' - ' + dialogOrder.expiratedTime
            }}
          </span>
          <span class="content" v-else> 永久 </span>
        </div>
        <!-- <div class="item" v-if="dialogOrder.vipExpiratedTime">
          <span class="label">VIP剩余时间：</span>
          <span class="content" v-if="!remainingTimeFlag">{{
            calculateTimeRemaining(remainingTime)
          }}</span>
        </div> -->
      </div>
      <template #footer v-if="dialogOrder.orderStatus == 0">
        <span class="dialog-footer">
          <el-button class="orderbtn" @click="paymentOrder">支付订单</el-button>
          <el-button class="cancelbtn" @click="handleCloseOrder(dialogOrder)">
            取消订单
          </el-button>
        </span>
      </template>
    </el-dialog>
    <OrderCode v-if="orderCode" />
  </div>
</template>

<script setup>
import OrderCode from '@/components/OrderCode'
import { reactive, toRefs, onMounted, computed, watch } from 'vue'
import TableView from '@/components/TableView'
import {
  getorderlist,
  closeorder,
  ordergetlist,
  getwechatorderstatus, getpersonalused
} from '@/api/order'
import { ElMessage } from 'element-plus'
import { clearStorage } from '@/utils/common'
import { convertTimestampToDate } from '@/utils/validate'
// calculateTimeRemaining
import { useStore } from 'vuex'
const store = useStore()
import { useRouter } from 'vue-router'
const router = useRouter()
const initData = reactive({
  rTableData: [],
  columns: [
    { prop: 'orderId', label: '#订单号' },
    { prop: 'createdAt', label: '创建时间' },
    // { prop: 'productName', label: '订阅类型' },
    { slot: 'productName' },
    { prop: 'price', label: '订单金额（元）' },
    { slot: 'status' },
    { slot: 'action' }
  ],
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  dialogOrder: {},
  orderId: '',
  timer: null,
  remainingTime: 0,
  remainingTimeFlag: false
})

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  dialogOrder,
  orderId,
  timer,
  remainingTime,
  remainingTimeFlag
} = toRefs(initData)
const orderCode = computed(() => store.state.common.orderCode)
const orderDialogVisible = computed(() => store.state.common.orderDialogVisible)
const vipTimer = computed(() => store.state.common.vipTimer)

onMounted(() => {
  store.commit('common/setModelCode', '')
  getDataList()
  if (localStorage.getItem('orderlist')) {
    const orderlist = JSON.parse(localStorage.getItem('orderlist'))
    handleDetails({ orderId: orderlist.orderId })
    store.commit('common/setOrderDialogVisible', true)
    setTimeout(() => {
      localStorage.removeItem('orderlist')
    }, 2000)
  }
})
watch(orderCode, newv => {
  if (!newv) {
    clearInterval(timer.value)
    clearInterval(vipTimer.value)
    getDataList()
  }
})

watch(
  () => router.currentRoute.value.path,
  (newv, oldv) => {
    if (newv == '/order') {
      if (router.currentRoute.value.query.a == 1) {
        const orderlist = JSON.parse(localStorage.getItem('orderlist'))
        handleDetails({ orderId: orderlist.orderId })
        store.commit('common/setOrderDialogVisible', true)
        setTimeout(() => {
          localStorage.removeItem('orderlist')
        }, 2000)
      }
    }
  },
  { deep: true }
)

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  getorderlist(data).then(response => {
    const { data: res, status } = response
    loading.value = false
    if (status == 200) {
      if (res.code == 0) {
        rTableData.value = res.data.data
        pagination.value.total = res.data.total
        rTableData.value.forEach(item => {
          item.createdAt = convertTimestampToDate(item.createdAt)
          item.codeExpiratedTime = convertTimestampToDate(
            item.codeExpiratedTime
          )
          item.expiratedTime = item.expiratedTime
            ? convertTimestampToDate(item.expiratedTime)
            : ''
          item.takingEffectTime = item.takingEffectTime
            ? convertTimestampToDate(item.takingEffectTime)
            : ''
        })
      } else {
        rTableData.value = []
        pagination.value.total = 0
      }
    }
    if (status == 202) {
      if (res.code == 1) {
        ElMessage.warning('验证失败，请重新登录或完成注册')
        clearStorage()
        setTimeout(() => {
          store.commit('common/setLoginMask', true)
        }, 3000)
      }
    }
  })
}

// const calculateTimeRemaining = timeDifference => {
//   const oneSecond = 1000
//   const oneMinute = oneSecond * 60
//   const oneHour = oneMinute * 60
//   const oneDay = oneHour * 24

//   const days = Math.floor(timeDifference / oneDay)
//   const hours = Math.floor((timeDifference % oneDay) / oneHour)
//   const minutes = Math.floor((timeDifference % oneHour) / oneMinute)
//   const seconds = Math.floor((timeDifference % oneMinute) / oneSecond)
//   return `${days}天${hours}小时${minutes}分钟${seconds}秒`
// }

const orderStatus = val => {
  let v = ''
  switch (Number(val)) {
    case 0:
      v = '待支付'
      break
    case 1:
      v = '支付中'
      break
    case 2:
      v = '已支付'
      break
    case 3:
      v = '已完成'
      break
    case 4:
      v = '已关闭'
      break
  }
  return v
}
const startTimer = time => {
  remainingTimeFlag.value = true
  setTimeout(() => {
    remainingTimeFlag.value = false
  }, 1000)
  timer.value = setInterval(() => {
    const currentTime = new Date().getTime()
    remainingTime.value = Math.max(0, Number(time) * 1000 - currentTime)
    if (remainingTime.value === 0) {
      clearInterval(timer.value)
      clearInterval(vipTimer.value)
    }
  }, 1000)
}

// 查看详情
const handleDetails = row => {
  orderId.value = row.orderId
  clearInterval(timer.value)
  clearInterval(vipTimer.value)
  ordergetlist({ orderId: row.orderId }).then(response => {
    const { data: res, status } = response
    if (status == 200) {
      if (res.code == 0) {
        res.data.forEach(item => {
          item.createdAt = convertTimestampToDate(item.createdAt)
          item.expiratedTime = item.expiratedTime
            ? convertTimestampToDate(item.expiratedTime)
            : ''
          item.takingEffectTime = item.takingEffectTime
            ? convertTimestampToDate(item.takingEffectTime)
            : ''
          // if (item.vipExpiratedTime && item.vipExpiratedTime != -1) {
          //   startTimer(item.vipExpiratedTime)
          // } else {
          //   item.vipExpiratedTime = ''
          // }
        })
        dialogOrder.value = res.data[0]
        store.commit('common/setOrderDialogVisible', true)
      }
    }
    if (status == 202) {
      if (res.code == 0) {
        ElMessage.warning(res.msg)
      }
    }
  })
}

// 判断二维码失效时间是否小于五分钟
const checkTimestamp = time => {
  const currentTime = new Date().getTime()
  const selectedTime = time * 1000
  const timeDifference = Math.abs((currentTime - selectedTime) / 1000)
  return timeDifference < 300
}

// 订单支付
const paymentOrder = () => {
  if (checkTimestamp(dialogOrder.value.codeExpiratedTime)) {
    store.commit('common/setOrderDialogVisible', false)
    handleDetails({ orderId: orderId.value })
  } else {
    store.commit(
      'common/setOrderCodeTime',
      Number(dialogOrder.value.codeExpiratedTime)
    )
    store.commit('common/setOrderCodeUrl', dialogOrder.value.codeUrl)
    store.commit('common/setOrderCode', true)
    timer.value = setInterval(() => {
      getwechatorderstatus({ orderId: orderId.value })
        .then(response => {
          const { data: res, status } = response
          if (status == 200) {
            if (res.code == 0 && res.data[0].orderStatus == 2) {
              ElMessage.success('支付成功')
              store.commit('common/setOrderCode', false)
              store.commit('common/setOrderDialogVisible', false)
              clearInterval(timer.value)
              clearInterval(vipTimer.value)
              clearInterval(vipTimer.value)
              store.dispatch('common/personal')
            }
          }
          if (status == 202) {
            if (res.code == 0) {
              clearInterval(timer.value)
              clearInterval(vipTimer.value)
              ElMessage.warning(res.msg)
            }
          }
        })
        .catch(() => {
          clearInterval(timer.value)
          clearInterval(vipTimer.value)
        })
    }, 2000)
  }
}

// 取消订单
const handleCloseOrder = row => {
  closeorder({ orderId: row.orderId }).then(response => {
    const { data: res, status } = response
    if (status == 200) {
      if (res.code == 0) {
        store.commit('common/setOrderDialogVisible', false)
        ElMessage.success(res.msg)
        getDataList()
      }
    }
    if (status == 202) {
      if (res.code == 0) {
        ElMessage.warning(res.msg)
        getDataList()
      }
    }
  })
}

// 关闭订单详情弹窗
const closeOrderDialogVisible = () => {
  clearInterval(timer.value)
  clearInterval(vipTimer.value)
  store.commit('common/setOrderDialogVisible', false)
  if (router.currentRoute.value.path == '/order') {
    router.replace('/order')
  }
}
</script>
<style lang="scss" scoped>
.orderMain {
  margin: 0 20px;
}
.orderMain {
  :deep(tbody) {
    color: #47270a;
  }
}
:deep(.orderDialog) {
  .el-overlay-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .el-dialog__body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .el-dialog__footer {
    text-align: center;
    .orderbtn {
      background: #f87804;
      border-color: #f87804 !important;
      color: #fff;
    }
    .cancelbtn {
      background: #f2f3f5;
      border-color: #f2f3f5 !important;
      color: #4e5969;
    }
  }
  .orderform {
    .item {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      .label {
        font-size: 14px;
        color: #86909c;
        text-align: right;
        width: 150px;
      }
      .content {
        font-size: 14px;
        color: #3e3f4f;
      }
      .orange {
        color: #f98d29;
      }
      .purple {
        color: #9b6cff;
      }
      .blue {
        color: #389bfa;
      }
    }
  }
}
.btnlink {
  cursor: initial;
}
:deep(.el-table .cell) {
  position: relative;
}
.btnlinkbox {
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  z-index: 10;
}
</style>
